import React, { useContext } from "react"
import { Helmet } from 'react-helmet';
import { StyledContent as Content } from "../components/content/Content";
import { StyledParagraph as Paragraph } from "../components/paragraph/Paragraph";
import { StyledLayoutSub as LayoutSub } from "../layouts/Sub";
import { StyledList as List } from "../components/list/List"
import { StyledHeadline as Headline } from "../components/headline/Headline";

// Context
import { SiteContext } from '../context/SiteContext';

export default function Home() {
    const siteContext = useContext(SiteContext);

    return <>
        <Helmet>
            <title>
                Impressum - Klickste UG
            </title>
        </Helmet>
        <LayoutSub
            theme={siteContext.theme}
            toggleTheme={siteContext.toggleTheme}
            changeNavigation={siteContext.changeNavigation}
            changeModal={siteContext.changeModal}
            hasLogo={true}
            activeNavigation={siteContext.activeNavigation}
            activeModal={siteContext.activeModal}>
            <Content>

                <Headline as={"h1"} none="extra-small" sm="small" hasSpacing={true}>Impressum</Headline>
                <Headline as="h2" sm="tiny" hasSpacing={true}>Wo?</Headline>
                <Paragraph hasSpacing={true} isNarrow={false}>
                    Klickste UG<br />
                    Graefestr. 51a<br />
                    10967 Berlin<br />
                </Paragraph>
                <Headline as="h2" sm="tiny" hasSpacing={true}>Wer?</Headline>
                <List
                    type="dl"
                    ratio="2/3"
                    list={
                        [
                            ["Vertretungsberechtigter Geschäftsführer", " Enrico Asmis"],
                            ["Kontakt", "fragste@klickste.berlin"]
                        ]
                    }
                />
                <Headline as="h2" sm="tiny" hasSpacing={true}>Was sonst noch?</Headline>

                <List
                    type="dl"
                    ratio="2/3"
                    list={
                        [
                            ["Amtsgericht", "Charlottenburg"],
                            ["Ust-ID", "DE349558283"],
                            ["StNr.", "37/384/50183"],
                            ["", "HRB 234868 B"]
                        ]
                    }
                />

            </Content>
        </LayoutSub >
    </>
}
