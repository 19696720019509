import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { StyledButton as Button } from '../components/button/Button';
import { StyledHeader as Header } from '../components/header/Header';
import { StyledFooter as Footer } from '../components/footer/Footer';
import { StyledSymbol as Symbol } from '../components/symbol/Symbol';
import { StyledLogo as Logo } from '../components/logo/Logo';
import { respondTo } from '../helpers/breakpoints/breakpoints';
import { colors } from '../helpers/colors/colors';
import { space } from '../helpers/space';

// Context

import { Theme } from '../types';
import { Link } from '../components/Link/Link';

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
  }
  body {
    color: ${colors.black};
    background: ${colors.white};
    font-family: "inter";
    font-size: 15px;
    line-height: 20px;
    ${respondTo('sm', {
      ['font-size']: `17px`,
      ['line-height']: `24px`,
    })}
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding-top: ${space(6)};
    font-smoothing: antialiased;
  }
    ${respondTo('lg', {
      ['padding-bottom']: `${space(12)}`,
    })}
  }
  a, a:active, a:visited {
    color: ${colors.black};
    text-decoration: none;

  }
  @media (prefers-color-scheme: dark) {
    body, a, a:active, a:visited {
      color: ${colors.white};
      background: ${colors.black};
    }
  }
  @media (prefers-color-scheme: light) {
    body, a, a:active, a:visited {
      color: ${colors.black};
      background: ${colors.white};
    }
  }
`;

export interface SubLayoutProps extends React.HTMLAttributes<HTMLElement> {
  theme?: Theme;
  isLoading: boolean;
  toggleTheme: (e: React.MouseEvent) => void;
  changeNavigation: (navigationTarget: string) => void;
  activeNavigation: string;
  hasLogo: boolean;
  changeModal: (modalName: string) => void;
  activeModal: string;
}

export const LayoutSub = styled.main.attrs({
  className: '',
})<SubLayoutProps>`
  position: relative;
`;

export const StyledLayoutSub: React.FC<SubLayoutProps> = (
  props: SubLayoutProps
): JSX.Element => {
  const {
    children,
    theme,
    toggleTheme,
    isLoading,
    changeNavigation,
    hasLogo,
    changeModal,
  } = props;

  return (
    <div style={{ position: 'relative' }}>
      <Header>
        <div
          className="logo-container"
          style={
            hasLogo
              ? {
                  transform: `translateX(0)`,
                  transition: 'all ease-in-out .5s',
                }
              : {
                  transform: `translateX(-100%)`,
                  transition: 'all ease-in-out .5s',
                }
          }
        >
          <Logo
            className="header-logo"
            onClick={() => changeNavigation('home')}
            height={48}
            to={'/'}
          />
        </div>
        <div>
          <Link to="/">
            <Button hasBorder={false} sm={'medium'} onClick={() => {}}>
              <Symbol
                symbolStyle="fal"
                symbolName={'arrow-left'}
                color={theme === 'light' ? colors.black : colors.white}
                size="sm"
              />
              Zurück
            </Button>
          </Link>
        </div>
      </Header>
      <LayoutSub isLoading={isLoading} {...props}>
        <GlobalStyle />

        {children}
      </LayoutSub>
      <Footer toggleTheme={toggleTheme} changeModal={changeModal} />
    </div>
  );
};

export default StyledLayoutSub;
