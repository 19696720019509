import * as React from 'react';
import styled from 'styled-components';

import { space } from '../../helpers/space';

export interface ContentProps extends React.HTMLAttributes<HTMLElement> {
  offset: number;
}

export const Content = styled.div.attrs({
  className: '',
})<ContentProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 ${space(1)};
  flex-direction: column;
  ul,
  ol {
    padding: 0;
    margin: ${space(0.5)} 0 ${space(0.5)} ${space(1)};
    li {
      line-height: ${space(1.5)};
      margin-bottom: ${space(0.5)};
    }
  }
`;

export const StyledContent: React.FC<ContentProps> = (
  props: ContentProps
): JSX.Element => {
  const { children, offset } = props;

  return <Content offset={offset}>{children}</Content>;
};

export default StyledContent;
