import React from 'react';
import styled from 'styled-components';
import { space } from '../../helpers/space/';
import { respondTo } from '../../helpers/breakpoints/breakpoints';

export interface ListProps extends React.HTMLAttributes<HTMLElement> {
  ratio: '1/2' | '1/3' | '2/3';
  type: 'dl';
  list: Array<Array<[string, string]>>;
}

export const DList = styled.dl.attrs({
  className: '',
})<ListProps>`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${space(0.5)};
  dt {
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    font-weight: 600;
    margin-top: ${space(0.5)};
    margin-bottom: 0;
    padding-right: 0;
    ${(props) => {
      let width = '50%';
      if (props.ratio === '1/3') {
        width = '33%';
      }
      if (props.ratio === '2/3') {
        width = '66%';
      }
      return respondTo('md', {
        ['margin-top']: `0`,
        ['margin-bottom']: `${space(0.5)}`,
        ['padding-right']: `${space(0.25)}`,
        ['width']: width,
      });
    }}
  }
  dd {
    margin: 0;
    padding: 0;
    margin-bottom: ${space(0.5)};
    width: 100%;
    ${(props) => {
      let width = '50%';
      if (props.ratio === '1/3') {
        width = '66%';
      }
      if (props.ratio === '2/3') {
        width = '33%';
      }
      return respondTo('md', {
        ['width']: width,
      });
    }}
  }
`;

const buildUlList = (list) => {
  return (
    <ul className={''}>
      {list.map((item, index) => {
        return (
          <>
            <li key={index} className={''}>
              {item}
            </li>
          </>
        );
      })}
    </ul>
  );
};

const buildOlList = (list) => {
  return (
    <ol className={''}>
      {list.map((item, index) => {
        return (
          <>
            <li key={index} className={''}>
              {item}
            </li>
          </>
        );
      })}
    </ol>
  );
};

export const StyledList: React.FC<ListProps> = (
  props: ListProps
): JSX.Element => {
  const { type, list } = props;

  switch (type) {
    default: {
      return (
        <DList {...props}>
          {list.map((item, index) => {
            return (
              <>
                <dt key={index}>{item[0]}</dt>
                <dd key={index + 10}>{item[1]}</dd>
              </>
            );
          })}
        </DList>
      );
    }
  }
};

export default StyledList;